const patterns = {
  number: /\d/,
  notANumber: /[^\d]/,
  arabicNumbers: /[\u0660-\u0669\u06f0-\u06f9]/,
  lowerCaseLetter: /[a-z]/,
  upperCaseLetter: /[A-Z]/,
  arabicLetters: /[\u0620-\u064A\u066E-\u06D3\u06D5\u06EE-\u06EF\u06FA-\u06FF]/,
  email:
    /^(([^<>()[\]\\.,;:\s@!"]+(\.[^<>()[\]\\.,;:\s@!"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

export const validateEmail = email => {
  if (!email) return false

  return patterns.email.test(String(email).toLowerCase())
}

export const hasChar = value =>
  patterns.lowerCaseLetter.test(String(value || '').toLowerCase())

export const hasOnlyNumbers = value =>
  !patterns.notANumber.test(String(value || ''))

export const validatePassword = pass => {
  if (!pass) {
    return {
      passwordIsValid: false,
      hasNumber: false,
      hasMinLength: false,
      hasLowerCaseLetter: false,
      hasUpperCaseLetter: false,
    }
  }

  const hasMinLength = pass.length >= 8

  const hasNumber = patterns.number.test(pass)
  const hasArabicNumber = patterns.arabicNumbers.test(pass)

  const hasLowerCaseLetter = patterns.lowerCaseLetter.test(pass)
  const hasUpperCaseLetter = patterns.upperCaseLetter.test(pass)
  const hasArabicLetter = patterns.arabicLetters.test(pass)

  const isArabicPassword = hasArabicNumber || hasArabicLetter

  if (!isArabicPassword) {
    return {
      passwordIsValid:
        hasMinLength && hasNumber && hasLowerCaseLetter && hasUpperCaseLetter,
      hasNumber,
      hasMinLength,
      hasLowerCaseLetter,
      hasUpperCaseLetter,
    }
  }

  const hasAnyNumber = hasNumber || hasArabicNumber
  const hasLetter = hasLowerCaseLetter || hasUpperCaseLetter || hasArabicLetter

  return {
    passwordIsValid: hasMinLength && hasAnyNumber && hasLetter,
    hasNumber: hasAnyNumber,
    hasMinLength,
    hasLetter,
  }
}

export const validatePhone = () => true
