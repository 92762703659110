import { rootPath, getBindingAddressQuery } from './paths'

const getAbsReturnUrl = returnUrl => {
  const bindingAddressQuery = getBindingAddressQuery()
  const query = bindingAddressQuery ? `?${bindingAddressQuery}` : ''

  const path = rootPath !== '' ? rootPath : '/'

  return new URL(
    decodeURIComponent(returnUrl || `${path}${query}`),
    window?.location?.href
  ).href
}

export default getAbsReturnUrl
