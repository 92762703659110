import StandardError from './StandardError'

class ThrottledError extends StandardError {
  constructor(details) {
    super(
      'The service was called more times then allowed in a short period. You may again after some time.',
      'ThrottledError',
      details
    )
  }
}

export default ThrottledError
