const composeQuerystring = valueObject => {
  return Object.keys(valueObject)
    .map(key => `${key}=${encodeURIComponent(valueObject[key] || '')}`)
    .join('&')
}

/* global __RUNTIME__ */
export const rootPath = (__RUNTIME__ && __RUNTIME__.rootPath) || ''
export const getBindingAddressQuery = () => {
  const search = window && window.location && window.location.search
  const searchParams = new URLSearchParams(search)
  const bindingAddress = searchParams.get('__bindingAddress')

  return bindingAddress ? `__bindingAddress=${bindingAddress}` : ''
}

const basePath = `${rootPath}/api/vtexid`

const paths = {
  startLogin: () => `${basePath}/pub/authentication/startlogin`,
  startCorporateLogin: ({ buyerOrg, returnUrl }) => {
    const qs = new URLSearchParams({
      buyerOrganization: buyerOrg,
      returnUrl,
    })

    return `${basePath}/corporate-login/start?${qs}`
  },
  getIdentityProviders: (account, scope) =>
    `${basePath}/pub/authentication/providers?scope=${scope}&accountName=${account}`,
  getLoginPreference: (scope, email) => {
    const qs = composeQuerystring({ scope, email })

    return `${basePath}/pub/authentication/userpreference?${qs}`
  },
  redirect: returnUrl => {
    const qs = returnUrl ? composeQuerystring({ returnUrl }) : ''

    return `${basePath}/pub/authentication/redirect?${qs}`
  },
  getSamlRedirectUrl: providerName =>
    `${basePath}/pub/saml/${providerName}/redirect`,
  getOAuthRedirectUrl: ({ providerName, errorFallbackUrl }) =>
    `${basePath}/pub/authentication/oauth/redirect?${composeQuerystring({
      providerName,
      errorFallbackUrl,
    })}`,
  getOAuthCallbackUrl: isPopup => `${basePath}/oauth/finish?popup=${!!isPopup}`,
  reauthenticateUser: audience => `${basePath}/refreshtoken/${audience}`,
  setPassword: () =>
    `${basePath}/pub/authentication/classic/setpassword?expireSessions=true`,
  sendEmailVerification: deliveryMethod =>
    `${basePath}/pub/authentication/accesskey/send${
      deliveryMethod ? `?deliveryMethod=${deliveryMethod}` : ''
    }`,
  validateToken: setPreference =>
    `${basePath}/pub/authentication/accesskey/validate?setPreference=${!!setPreference}`,
  validatePassword: () => `${basePath}/pub/authentication/classic/validate`,
  validatePasskey: () => `${basePath}/pub/authentication/passkey/validate`,
  startMfaSetup: () => `${basePath}/pvt/mfa/start`,
  validateMfa: () => `${basePath}/pub/mfa/validate`,
  registerMfaPhoneNumber: () => `${basePath}/pub/mfa/registerphone`,
  registerMfaAuthenticator: () => `${basePath}/pub/mfa/registerAuthenticator`,
  resendMfa: () => `${basePath}/pub/mfa/resend`,
  setPasswordAfterLogin: (scope, setPreference) => {
    return `${basePath}/user/password/quickset?scope=${scope}&setPreference=${!!setPreference}`
  },
  logout: (scope, returnUrl) => {
    const qs = composeQuerystring({
      scope,
      returnUrl,
    })

    return `${basePath}/pub/logout?${qs}`
  },
  oAuthErrorFallback: () => `${basePath}/oauth/error`,
  userInfo: scope => `${basePath}/user/info?scope=${scope}`,
  getPhoneNumberByEmail: email => {
    const encodedQueryString = new URLSearchParams({ email }).toString()

    return `${basePath}/pub/authentication/accesskey/telephone?${encodedQueryString}`
  },
}

export default paths
