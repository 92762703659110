import StandardError from './StandardError'

class UnauthorizedUser extends StandardError {
  constructor(details) {
    super(
      'Unauthorized user. Please, check your credentials and try again',
      'UnauthorizedUser',
      details
    )
  }
}

export default UnauthorizedUser
