import { fromUint8Array, toUint8Array } from 'js-base64'

import { BlockedUserError, ThrottledError, UnexpectedError } from '../errors'
import Constants from './constants'
import getAbsReturnUrl from './getAbsReturnUrl'
import getFingerprintIfAdmin from './getFingerprintIfAdmin'
import Paths from './paths'

const getReqHeaders = ({ contentType, parentAppId }) => {
  const uiPrefix = parentAppId ? `${parentAppId}/` : ''
  const selfAppId = process.env.VTEX_APP_ID || process.env.VTEX_APP_NAME || ''

  return {
    ...(contentType && { 'Content-Type': contentType }),
    'vtex-id-ui-version': `${uiPrefix}${selfAppId}`,
  }
}

const buildFormData = valueObject => {
  const form = new FormData()

  Object.keys(valueObject).forEach(key =>
    form.append(key, valueObject[key] || '')
  )

  return form
}

const parseResBody = res => {
  const contentType = res.headers.get('Content-Type') || ''

  if (contentType.startsWith('application/json')) {
    return res.json()
  }

  return res.text()
}

const getOAuthCallbackUrl = ({ isPopup }) => {
  return Paths.getOAuthCallbackUrl(isPopup)
}

export const startSession = async ({
  accountName,
  returnUrl,
  scope,
  user,
  fingerprint,
  useOAuthPopup,
  parentAppId,
}) => {
  const { href } = (window && window.location) || {}
  const absoluteReturnUrl = getAbsReturnUrl(returnUrl)

  const absoluteCallbackUrl = new URL(
    getOAuthCallbackUrl({ isPopup: useOAuthPopup }),
    href
  )

  const body = buildFormData({
    accountName,
    scope: scope === Constants.Scopes.ADMIN ? '' : accountName,
    returnUrl: absoluteReturnUrl,
    callbackUrl: absoluteCallbackUrl.href,
    user,
    fingerprint,
  })

  const res = await fetch(Paths.startLogin(), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {},
    }
  }
}

export const redirectCorporateLogin = async ({ returnUrl, buyerOrg }) => {
  const absReturnUrl = getAbsReturnUrl(returnUrl)

  window.location.href = Paths.startCorporateLogin({
    returnUrl: absReturnUrl,
    buyerOrg,
  })
}

export const getIdentityProviders = async ({
  accountName,
  scopeName,
  parentAppId,
}) => {
  const scope = scopeName === Constants.Scopes.ADMIN ? '' : accountName

  const res = await fetch(Paths.getIdentityProviders(accountName, scope), {
    headers: getReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  if (res.status !== 200) {
    throw new UnexpectedError()
  }

  return res.json()
}

export const getLoginPreference = async ({
  accountName,
  scopeName,
  email,
  parentAppId,
}) => {
  const scope = scopeName === Constants.Scopes.ADMIN ? '' : accountName

  const res = await fetch(Paths.getLoginPreference(scope, email), {
    headers: getReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  if (res.status !== 200) {
    throw new UnexpectedError()
  }

  return res.json()
}

export const withSession =
  ({
    accountName,
    returnUrl,
    scope,
    user,
    fingerprint,
    useOAuthPopup,
    parentAppId,
  }) =>
  callback =>
    startSession({
      accountName,
      returnUrl,
      scope,
      user,
      fingerprint,
      useOAuthPopup,
      parentAppId,
    }).then(() => (callback ? callback() : null))

export const sendVerificationCode = async ({
  email,
  deliveryMethod,
  locale,
  recaptcha,
  recaptchaToken,
  parentAppId,
}) => {
  const body = buildFormData({
    email,
    locale,
    recaptcha,
    recaptchaToken,
    parentAppId,
  })

  const res = await fetch(Paths.sendEmailVerification(deliveryMethod), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (res.status === 401) {
    throw new BlockedUserError()
  }

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const validateToken = async ({
  login,
  accesskey,
  recaptcha,
  setPreference,
  parentAppId,
  recaptchaToken,
}) => {
  const body = buildFormData({
    login,
    accesskey,
    recaptcha,
    recaptchaToken,
  })

  const res = await fetch(Paths.validateToken(setPreference), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (res.status === 401) {
    throw new BlockedUserError()
  }

  if (!res.ok) {
    throw {
      response: {
        statusCode: res.status,
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const redirect = ({ returnUrl }) => {
  if (!window || !window.location) {
    return
  }

  const absoluteReturnUrl = getAbsReturnUrl(returnUrl)

  window.location.href = Paths.redirect(absoluteReturnUrl)
}

export const redirectSaml = ({ provider }) => {
  if (!window || !window.location || !provider) {
    return
  }

  window.location.href = Paths.getSamlRedirectUrl(provider)
}

export const redirectOAuth = ({ provider, errorFallbackUrl }) => {
  if (!window || !window.location || !provider) {
    return
  }

  window.location.href = Paths.getOAuthRedirectUrl({
    providerName: provider,
    errorFallbackUrl,
  })
}

export const getOAuthRedirectUrl = ({ provider, errorFallbackUrl }) =>
  Promise.resolve({
    url: Paths.getOAuthRedirectUrl({
      providerName: provider,
      errorFallbackUrl,
    }),
  })

export const setPassword = async ({
  login,
  newPassword,
  currentPassword,
  accesskey,
  recaptcha,
  parentAppId,
}) => {
  const body = buildFormData({
    login,
    newPassword,
    currentPassword,
    accesskey,
    recaptcha,
  })

  const res = await fetch(Paths.setPassword(), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const setPasswordAfterLogin = async ({
  accountName,
  scope,
  password,
  setPreference,
  parentAppId,
}) => {
  const body = {
    password,
    expireSessions: true,
  }

  const account = scope === Constants.Scopes.ADMIN ? '' : accountName

  const res = await fetch(Paths.setPasswordAfterLogin(account, setPreference), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: getReqHeaders({ parentAppId, contentType: 'application/json' }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const validatePassword = async ({
  login,
  password,
  recaptcha,
  fingerprint,
  parentAppId,
  recaptchaToken,
}) => {
  const body = buildFormData({
    login,
    password,
    recaptcha,
    fingerprint,
    recaptchaToken,
  })

  const res = await fetch(Paths.validatePassword(), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const getAssertionOptions = async () => {
  const response = await fetch(
    `${window.location.protocol}//${window.location.host}/api/users-assets/pub-key/assertion/options`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )

  const payload = await response.json()

  return {
    challenge: toUint8Array(payload.challenge),
    allowCredentials: payload.allowCredentials.map(cred => {
      return {
        id: toUint8Array(cred.id),
        type: cred.type,
      }
    }),
    timeout: 60000,
    userVerification: payload.userVerification,
    status: payload.status,
    extensions: payload.extensions,
    rpId: payload.rpId,
  }
}

export const validatePasskey = async ({
  assertion,
  requestOptions,
  parentAppId,
}) => {
  const body = JSON.stringify({
    clientResponse: {
      rawId: fromUint8Array(assertion.rawId),
      response: {
        authenticatorData: fromUint8Array(assertion.response.authenticatorData),
        clientDataJSON: fromUint8Array(assertion.response.clientDataJSON),
        signature: fromUint8Array(assertion.response.signature),
        userHandle: fromUint8Array(assertion.response.userHandle),
      },
      ...assertion,
    },
    assertionOptions: {
      challenge: fromUint8Array(requestOptions.challenge),
      allowCredentials: requestOptions.allowCredentials.map(cred => {
        return {
          id: fromUint8Array(cred.id),
          ...cred,
        }
      }),
      ...requestOptions,
    },
  })

  const res = await fetch(Paths.validatePasskey(), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const startMfaSetup = async ({ parentAppId }) => {
  const res = await fetch(Paths.startMfaSetup(), {
    method: 'POST',
    headers: getReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {},
    }
  }
}

export const withMfaSession = (callback, { parentAppId }) =>
  startMfaSetup({ parentAppId }).then(() => (callback ? callback() : null))

export const validateMfa = async ({ mfaToken, recaptcha, parentAppId }) => {
  const body = buildFormData({
    mfaToken,
    recaptcha,
  })

  const res = await fetch(Paths.validateMfa(), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const registerMfaPhoneNumber = async ({ phoneNumber, parentAppId }) => {
  const body = buildFormData({
    phoneNumber,
  })

  const res = await fetch(Paths.registerMfaPhoneNumber(), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const resendMfa = async ({ parentAppId }) => {
  const body = buildFormData({})

  const res = await fetch(Paths.resendMfa(), {
    method: 'POST',
    body,
    headers: getReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export const registerMfaAuthenticator = async ({ parentAppId }) => {
  const res = await fetch(Paths.registerMfaAuthenticator(), {
    method: 'POST',
    headers: getReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  const resBody = await res.json()

  if (!resBody || !resBody.qRCodeUrl) {
    return resBody
  }

  return {
    ...resBody,
    qRCodeUrl: resBody.qRCodeUrl.replace('chs=150x150', 'chs=200x200'),
  }
}

export const redirectLogout = async ({ account, scope, returnUrl }) => {
  if (!window || !window.location) {
    return
  }

  const absoluteReturnUrl = getAbsReturnUrl(returnUrl)

  window.location.href = Paths.logout(
    scope === Constants.Scopes.ADMIN ? '' : account,
    absoluteReturnUrl
  )
}

export const logout = async ({ account, scope, parentAppId }) => {
  const res = await fetch(
    Paths.logout(scope === Constants.Scopes.ADMIN ? '' : account),
    {
      headers: getReqHeaders({ parentAppId }),
    }
  )

  if (!res.ok) {
    throw {
      response: {},
    }
  }
}

export const reauthenticateUser = async ({
  scopeName,
  parentAppId,
  fingerprint,
}) => {
  const audience = Constants.Audience[scopeName] || Constants.Audience.ADMIN

  const res = await fetch(Paths.reauthenticateUser(audience), {
    method: 'POST',
    body: JSON.stringify({ fingerprint }),
    headers: getReqHeaders({ parentAppId, contentType: 'application/json' }),
  })

  if (res.status !== 200) {
    return null
  }

  return res.json()
}

const initialReauthentication = async ({
  fingerprintPromise,
  scopeName,
  parentAppId,
  isAdmin,
}) => {
  if (isAdmin) {
    const fingerprint = await fingerprintPromise

    const data = await reauthenticateUser({
      scopeName,
      parentAppId,
      fingerprint,
    })

    if (!data || data.status !== 'Success') {
      return {
        isUserAuthenticated: false,
        userId: null,
      }
    }

    return {
      isUserAuthenticated: true,
      userId: data.userId,
    }
  }

  return {
    isUserAuthenticated: false,
    userId: null,
  }
}

export const getInitialData = ({ accountName, scopeName, parentAppId }) => {
  const isAdmin = scopeName === Constants.Scopes.ADMIN

  const fingerprintPromise = getFingerprintIfAdmin(scopeName)

  return Promise.all([
    getIdentityProviders({ accountName, scopeName, parentAppId }),
    initialReauthentication({
      fingerprintPromise,
      scopeName,
      parentAppId,
      isAdmin,
    }),
    fingerprintPromise,
  ]).then(([identityProviders, reauthenticationResult, fingerprint]) =>
    Promise.resolve({ identityProviders, reauthenticationResult, fingerprint })
  )
}

export const getUserInfo = async ({ account, scopeName, parentAppId }) => {
  const scope = scopeName === Constants.Scopes.ADMIN ? '' : account

  const res = await fetch(Paths.userInfo(scope), {
    headers: getReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  if (res.status !== 200) {
    throw new UnexpectedError()
  }

  return res.json()
}

export const getPhoneNumberByEmail = async ({ email, parentAppId }) => {
  const res = await fetch(Paths.getPhoneNumberByEmail(email), {
    headers: getReqHeaders({ parentAppId }),
  })

  if (res.status === 404) {
    return { type: 'phoneNotFoundErr' }
  }

  if (!res.ok) {
    throw new Error('Unknown response')
  }

  const result = await res.json()

  return {
    type: 'success',
    phoneNumber: result.telephone,
  }
}
