import StandardError from './StandardError'

class PasswordAlreadyUsedError extends StandardError {
  constructor(details) {
    super(
      "This account doesn't allow repeated passwords to be created.",
      'PasswordAlreadyUsed',
      details
    )
  }
}

export default PasswordAlreadyUsedError
